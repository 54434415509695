.SmallHeader {
  font-size: initial;
  margin-bottom: 20px;
}

.RemoveWrapper {
  position: relative;
  float: right;
  margin-bottom: -1.1rem;
  z-index: 1;
}
