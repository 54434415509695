@import '~@sparkpost/design-tokens/tokens';

$nav-height: spacing(800);
$section-padding: spacing(500);
$background-color: color(gray, 900);
$border-color: color(gray, 400);

.FullPageNav {
  align-items: center;
  background: $background-color;
  color: color(white);
  display: flex;
  height: $nav-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.FullPageHeaderArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 $section-padding;
}

.FullPageBreadcrumb {
  align-items: center;
  background: $background-color;
  border-right: 1px solid $border-color;
  color: color(white) !important;
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  margin: 0;
  padding: 0 $section-padding;
  transition: background motion-duration(fast) motion-ease(in-out);

  &:hover {
    background: color(gray, 1000);
  }
}

.FullPageChevron {
  height: spacing(600);
  width: spacing(600);
}

.FullPageTitle {
  color: color(white);
  font-size: font-size(500);
  font-weight: font-weight(normal);
  margin: 0;
}

.FullPageContents {
  padding-top: $nav-height;
}

.demoLink, .demoLink:visited{
  color: #39444D;
}
