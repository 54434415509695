@import '~@sparkpost/design-tokens/tokens';

.LabelledValue {
  display: flex;
  margin-bottom: spacing(200);
}

.LabelContainer {
  flex: 0 0 0;
  align-items: flex-start;
  min-width: 11 * spacing(400);
}

.Label {
  display: inline-block;
  line-height: line-height(300);
  font-weight: font-weight(semibold);
  color: color(gray, 900);
}

.Content {
  flex: 1 0 0;
  margin-left: spacing(400);
  word-break: break-all;
  word-wrap: break-word;

  h6 {
    font-size: font-size(200);
    line-height: line-height(300);
    margin-bottom: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
