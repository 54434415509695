@import '~@sparkpost/design-tokens/tokens';
@import './phone.scss';

.EditorWrapper {
  height: 90vh; // must match editor wrapper
  min-height: $phone-container-min-height;
  padding: $phone-padding 0;
  background-color: #272727;

  :global(.ace_error) {
    background: linear-gradient(to right, color(red, 700) 5px, transparent 5px) !important;
    color: inherit !important;
  }

  &.ReadOnly,
  &.ReadOnly :global(.ace_content) {
    cursor: not-allowed;
    user-select: none;
  }

  // Visually hide the cursor when in read only mode
  &.ReadOnly :global(.ace_cursor) {
    opacity: 0;
    visibility: hidden;
  }
}
