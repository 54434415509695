@import '~@sparkpost/design-tokens/tokens';

.Animator {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s cubic-bezier(0, 0, 0.5, 1.5);
  transform: translate(-#{spacing(600)}, 0);
}

.entered {
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
}

.exiting,
.exited {
  transform: translate(-#{spacing(600)}, 0);
  opacity: 0;
}

.exited {
  height: 0 !important;
  visibility: hidden;
  pointer-events: none;
}
