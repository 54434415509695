@import '~@sparkpost/design-tokens/tokens';

$nav-height: spacing(800);

.EditorNav {
  position: sticky;
  width: 100%;
  top: $nav-height; // Note `top` offset by the exact height of the navigation above found in `FullPage.module.scss`
  z-index: 50;

  
}

.MainContent {
  margin-top: 0;

 
}

.GreenColor {
  color: color(green, 500);
}

.StatusContent {
  color: color(gray, 700);
}
