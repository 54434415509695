@import '~@sparkpost/design-tokens/tokens';

.MaxWidthSM {
  max-width: 325px;
}

.MaxWidthMD {
  max-width: 600px;
}

.FieldGroup,
.Card {
  margin-bottom: spacing(600);
}

.RadioGroup {
  margin-bottom: spacing(200);
  border: 0;
  padding-left: 0;
}

.RadioParagraph {
  margin-bottom: spacing(300);
}

.CardParagraph {
  margin-bottom: 0;
}

.WarmupDocLink {
  margin-top: spacing(200);
}
