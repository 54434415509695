@import '~@sparkpost/design-tokens/tokens';
@import '~src/styles/resets';

.FullBanner {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  background-color: color(blue, 700);
  height: spacing(700);
  padding: 0 spacing(400);
}

.FullBannerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.CloseButton {
  position: absolute;
  padding: spacing(100);
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%); // Vertical centering hack
  color: color(white);

  &:hover,
  &:focus {
    position: absolute; // Overrides some styles stemming from Matchbox
    background: transparent;
    color: color(white);
    outline-color: color(white);
  }
}

.Icon {
  display: inline-block;
  margin-right: spacing(200);
}

.Link.Link {
  @include button-reset();
  text-decoration: underline;
  font-size: font-size(300);
  margin: 0 spacing(200);
  color: color(white);
  cursor: pointer;

  &:hover,
  &:focus {
    color: color(white);
  }
}
