@import '~@sparkpost/design-tokens/tokens';

.Header {
  margin-bottom: spacing(300);
}

.SubHeader {
  margin: spacing(600) 0 0 0;
  font-size: font-size(200);
  font-weight: 600;
}

.ExampleMethod {
  font-size: font-size(100);
  background-color: color(gray, 200);
  padding: 4px;
}

.ApiDocsLink {
  margin-left: spacing(300);
}

.Method {
  margin-right: spacing(800);
  color: color(blue, 800);
  font-weight: 600;
}

.CodeSection {
  min-width: 350px;

  * {
    font-size: font-size(100);
  }
}

.tab {
  padding-left: 1em;
}

.blue {
  color: #8fc4fe;
}

.white {
  color: color(gray, 100);
}
