@import '~@sparkpost/design-tokens/tokens';
@import 'src/styles/resets';

.PlanPicker {
  border-bottom: 1px solid color(gray, 400);
  position: relative; // for the menu
}

.PlanPickerHeader {
  margin: 0; // OG only
  padding: spacing(400) spacing(400) 0;
}

.PlanPickerHelpText {
  font-size: font-size(200);
  font-weight: font-weight(light);
  padding: 0 spacing(400) spacing(400);
}

.PlanPickerList {
  background: color(white);
  border-radius: 0;
  box-shadow: box-shadow(400);
  left: 0;
  list-style-type: none;
  max-height: sizing(1000);
  opacity: 0;
  overflow: auto;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-3px);
  transition: 0.15s;
  z-index: 100;

  &.open {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
}

.PlanPickerOption {
  @include button-reset();
  padding: spacing(400);
  cursor: pointer; // why?
  text-align: left;
  width: 100%;

  &:hover,
  &.highlighted {
    background: color(gray, 100);
  }

  &.selected {
    color: color(blue, 700);
  }
}

.PlanPickerSelectedOption {
  color: color(blue, 700);
}

.PlanPickerTrigger {
  @include button-reset();
  align-items: center; // to vertically center icon
  color: color(blue, 700);
  cursor: pointer; // why?
  display: flex;
  justify-content: space-between; // to align icon to right
  padding: spacing(400);
  text-align: left;
  width: 100%;

  &:focus,
  &:hover {
    background: color(gray, 100);
  }
}
