@import '~@sparkpost/design-tokens/tokens';
.Id {
  margin-bottom: 0;
  line-height: line-height(300);
  font-size: font-size(300);
}
.LastUpdated {
  margin-bottom: 0;
}
.PublishedWithChanges {
  white-space: nowrap;
}
.published {
  white-space: nowrap;
}
.PublishedIconColor {
  color: color(green, 700);
}
.Action {
  display: inline-flex;
  height: spacing(600);
  width: spacing(600);
  padding: 0;
  align-items: center;
  justify-content: center;
}
