@import '~@sparkpost/design-tokens/tokens';

.ActionItem {
  display: flex;
  align-items: center;
  transition: background motion-duration(fast) motion-ease(in-out),
    color motion-duration(fast) motion-ease(in-out);

  a {
    font-weight: 400;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: font-size(200);
    font-weight: font-weight(normal);
    text-decoration: none;
    color: color(gray, 800);
    padding: spacing(200) spacing(300);
    transition: 0.15s;
    cursor: pointer;

    &:hover {
      background: color(blue, 200);
      color: color(blue, 800);
    }

    svg {
      margin-right: spacing(200);
    }
  }
}

.Actions {
  display: inline-flex;
  margin-right: -1px;
}

.ActionPopover {
  transform: translateY(spacing(200));
}

.ActionsPrimaryLink {
  margin-left: spacing(500);
}

.ActionsBody {
  margin-top: 0;
}

.Divider {
  margin-bottom: 0;
  margin-top: 0;
}
