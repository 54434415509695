@import '~@sparkpost/design-tokens/tokens';

.ChangePlanForm {
  display: grid;
  grid-gap: spacing(200);
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @media (min-width: media-query(lg)) {
    grid-template-columns: 1fr 1fr;
  }
}

.CurrentPlanSection {
  grid-column: 1;
  grid-row: 1;

  @media (min-width: media-query(lg)) {
    grid-column: 2;
    grid-row: 1;
  }
}

.MainContent {
  grid-column: 1;
  grid-row: 2;

  @media (min-width: media-query(lg)) {
    grid-column: 1;
    grid-row: 1;
  }
}
