.Support {
  min-height: 530px;
}

.SupportContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 530px;
  justify-content: center;
  text-align: center;
}
