@import '~@sparkpost/design-tokens/tokens';

.Plan {
  > span:first-child {
    font-size: font-size(500);
  }

  > span:nth-child(2) {
    font-size: font-size(300);
  }
}
