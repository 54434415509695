@import '~@sparkpost/design-tokens/tokens';

.Wrapper {
  position: fixed;
  bottom: spacing(400);
  left: spacing(400);
  top: unset;
  right: unset;
  z-index: 9999; // Overlay all other overlays!
  text-align: left;
  overflow: hidden;
  pointer-events: none;
}

.Alert {
  padding-top: spacing(400);

  & > * {
    pointer-events: auto;
    text-align: left;
  }
}
