@import '~@sparkpost/design-tokens/tokens';
@import 'src/styles/resets';

$header-height: spacing(800);

.MobileNavigation {
  display: block;

  @media (min-width: media-query(md)) {
    display: none;
  }
}

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: color(white);
  padding: 0 spacing(400);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: z-index(overlay);
}

.Logo {
  height: spacing(700);
}

.ToggleButton {
  @include button-reset();
  outline-offset: 15px; // Helps with weird outline edge shift occurring due to animation
}

.Hamburger {
  position: relative;
  height: 30px;
  width: 30px;

  span,
  span:before,
  span:after {
    position: absolute;
    background-color: color(brand, orange);
    transition: transform 0.1s cubic-bezier(0, 0.4, 0.2, 1), width 0.1s cubic-bezier(0, 0.4, 0.2, 1);
  }

  span {
    top: 50%;
    margin-top: -1px;
    left: 2px;
    right: 2px;
    height: 2px;
    width: 60%;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      transform-origin: center;
    }

    // Bottom line
    &:before {
      top: 7px;
      width: 120%;
    }

    // Top line
    &:after {
      top: -7px;
      width: 140%;
    }
  }
}

.showClose span {
  background: transparent;
  transform: rotate(45deg);
  width: 100%; // resets width to full
  background-color: color(gray, 900);

  // resets width to full
  &:before,
  &:after {
    background-color: color(gray, 900);
    width: 100%;
    top: 0px;
  }

  &:before {
    transform: rotate(0deg);
  }

  &:after {
    transform: rotate(90deg);
  }
}

.Menu,
.Menu.exiting,
.Menu.exited {
  visibility: hidden;
  transform: translateX(-100%);
}

.Menu {
  position: fixed;
  top: $header-height;
  left: 0;
  height: 100vh;
  width: 80vw;
  outline: 0; // Disables focus styles (focusing only occurs via programmatic focus)
  max-width: media-query(xs);
  background-color: color(gray, 1000);
  z-index: z-index(overlay);
  overflow-y: scroll;
  margin-bottom: spacing(600);
  transition: visibility motion-duration(medium) motion-ease(in-out),
    transform motion-duration(medium) motion-ease(in-out);

  &.entering,
  &.entered {
    visibility: visible;
    transform: translateX(0);
  }
}

.MenuMeta {
  background-color: color(white);
  color: scale-color(color(brand, orange), $lightness: -25%);
  padding: spacing(400);
}

.MenuUserInfo {
  font-weight: font-weight(medium);
}

.MenuUserSupplemental {
  text-transform: capitalize;
}

.Overlay,
.Overlay.exited {
  opacity: 0;
}

.Overlay {
  position: fixed; // Allows use of z-index
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: z-index(below);
  background-color: rgba(color(gray, 900), 0.75);
  transition: opacity motion-duration(medium) motion-ease(in);

  &.entering,
  &.exiting,
  &.entered {
    z-index: z-index(overlay) - 1;
  }

  &.entering,
  &.entered {
    opacity: 1;
  }
}

.MenuItem.MenuItem {
  @include button-reset();
  display: flex;
  padding: 0 spacing(400);
  width: 100%;
  max-width: 100%;
  font-size: font-size(400);
  font-weight: font-weight(medium);
  letter-spacing: 0.0125rem;
  text-decoration: none;
  transition: background-color motion-duration(fast) motion-ease(in-out);

  &.primary {
    background-color: color(white);
    color: color(gray, 800);

    &:hover,
    &:focus {
      color: color(gray, 800);
      background-color: color(gray, 100);
    }
  }

  &.secondary {
    background-color: transparent;
    color: color(white);

    &:hover,
    &:focus {
      background-color: color(gray, 800);
    }
  }

  &:first-child {
    .MenuItemContent {
      border-color: transparent; // Prevents jumpiness from adding/removing border
    }
  }
}

.ChildItem.ChildItem {
  display: flex;
  text-decoration: none;
  font-weight: font-weight(medium);
  color: color(gray, 700);
  background-color: color(white);
  padding-left: spacing(600);
  transition: background-color motion-duration(fast) motion-ease(in-out);

  &:first-child {
    .MenuItemContent {
      border-color: transparent; // Prevents jumpiness from adding/removing border
    }
  }

  &:hover,
  &:focus {
    background-color: color(gray, 100);
    color: color(gray, 700);
  }
}

.MenuItemContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: spacing(400) 0;
  border-top: 1px solid color(gray, 300);
}

.ExpandableIcon {
  width: spacing(500);
  height: spacing(500);
  transform: rotate(90deg);

  &.isExpanded {
    transform: rotate(-90deg);
  }
}

.Footer {
  padding: spacing(400) 0;
  margin-bottom: spacing(800); // Needed to help prevent content from overflowing the window
}
