@import '~@sparkpost/design-tokens/tokens';

.PlanRow {
  margin: 0.5rem 1rem;
  padding: 0.5rem 0;
  display: flex;

  :first-child {
    flex-grow: 1;
  }
}

.tierPlans {
  & > *:not(:last-child) {
    border-bottom: 1px solid color(gray, 400);
  }

  margin: 0;
}

.tierLabel {
  font-weight: font-weight(medium);
}

.selectButton {
  padding-left: 20px;
  padding-right: 20px;
}

.SelectedPlan {
  color: color(blue, 700);
}

.CheckIcon {
  position: absolute;
  transform: translate(-20px, 2px);
}
