@import '~@sparkpost/design-tokens/tokens';
@import 'src/styles/resets';

$nav-height: 52px;
$logo-width: 130px;

.DesktopNavigation {
  background-color: color(white);
  display: none;

  @media (min-width: media-query(md)) {
    display: block;
  }
}

.Wrapper {
  padding: spacing(300) spacing(500);
}

.SubWrapper {
  max-width: media-query(lg);
  margin: 0 auto;
  position: relative; // allows absolute positioning within
}

.PrimaryNavLayout {
  display: flex;
  justify-content: space-between;
  height: $nav-height;
}

.PrimaryNav {
  display: flex;
  flex: 1;

  align-self: center;
  margin: 0 0 0 spacing(500);
}

// Some specificity issues with links
.SkipLink.SkipLink.SkipLink {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  text-decoration: none;
  background-color: rgba(color(blue, 800), 1);
  padding: spacing(100) spacing(200);
  font-size: font-size(200);
  color: color(white);
  transform: scale(0.95);
  z-index: -1;
  transition: opacity motion-duration(fast) motion-ease(in-out),
    transform motion-duration(fast) motion-ease(in-out);

  &:focus {
    opacity: 1;
    transform: scale(1);
    z-index: 1;
  }
}

.LogoWrapper {
  align-self: center;
  transform: translateY(3px);
}

.Logo {
  width: $logo-width;
}

// Some specificity issues with visited links
.NavLink.NavLink {
  display: inline-flex;
  font-size: font-size(200);
  padding: spacing(200) spacing(400);
  border-radius: border-radius(pill);
  outline-offset: -8px; // helps ensure focus outline is visible
  text-decoration: none;
  transition: color motion-duration(fast) motion-ease(in-out),
    background-color motion-duration(fast) motion-ease(in-out);

  &.primary.primary {
    color: color(gray, 800);

    &:hover,
    &.isActive {
      color: color(gray, 800);
      background-color: color(gray, 300);
      outline-color: color(blue, 800);
    }
  }

  &.secondary.secondary {
    color: color(white);

    &:hover,
    &.isActive {
      color: color(white);
      background-color: color(gray, 700);
      outline-color: color(white);
    }
  }
}

.NavLinkActive {
  color: color(gray, 800);
  background-color: color(gray, 300);
}

.SecondaryNav {
  background-color: color(gray, 900);
}
