@import '~@sparkpost/design-tokens/tokens';

.currentPlan {
  color: color(gray, 600);

  .Title {
    font-weight: font-weight(normal);
  }

  .Plan {
    margin: 1rem 0 0 1rem;
  }
}

.DeprecatedWarning {
  color: color(red, 700);
  display: flex;
  font-size: font-size(400);
  line-height: spacing(400);

  .content {
    padding: 0 spacing(500);
    flex: 1 0 0;
  }
}
