@import '~@sparkpost/design-tokens/tokens';

.ListProgress {
  margin: spacing(200) * 3 0;
}

.Header {
  margin-bottom: spacing(200);
}

.Container {
  width: 80%;
  padding: spacing(400);
}

.ListProgressStatus {
  margin-bottom: spacing(300);
}

.ProgressBar {
  position: relative;
  height: rem(10);
  background: color(gray, 300);
  border-bottom: none;
  border-radius: border-radius(200);
}

.Progress {
  height: rem(10);
  background: color(blue, 700);
  border-radius: border-radius(200);
  box-shadow: shadow();
  transition: 0.15s;
}
