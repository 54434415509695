@import '~@sparkpost/design-tokens/tokens';

.modalContainer {
  max-width: 800px;
  margin: 0 auto;
}

.bodyContainer {
  max-width: 800px;
}

.rvModalCell {
  padding: 8px 0 4px 0;
}
