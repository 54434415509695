@import '~@sparkpost/design-tokens/tokens';

.MetricDisplay {
  display: inline-block;
  text-align: left;

  &:not(:last-child) {
    margin-right: spacing(600);
  }
}

.Label {
  margin: 0;
  color: color(gray, 800);
  font-weight: font-weight(semibold);
  font-size: font-size(300);
}

.Value {
  position: relative;
  display: inline-block;
  margin: 0;
  color: color(gray, 800);
  font-weight: font-weight(normal);
  font-size: font-size(400);
}

.Icon {
  position: absolute;
  top: 0;
  left: 100%;
}
