@import '~@sparkpost/design-tokens/tokens';

.Name {
  margin-bottom: 0;
}

.Id {
  margin-bottom: 0;
  line-height: 1.4em;
  font-size: font-size(200);
}

.LastUpdated {
  margin-bottom: 0;
}

.Template {
  margin-bottom: 0;

  .Winner {
    font-weight: 600;
  }
}
