@import '~@sparkpost/design-tokens/tokens';

.HeaderCell {
  vertical-align: bottom;
  word-break: normal;
}

.FirstColumnHeader {
  min-width: 200px;
}

.NumericalHeader {
  text-align: right;
  min-width: 110px;

  max-width: 200px;
}
