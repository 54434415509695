.st0{fill:#828287}
.st1{fill:#bebec3}
.st2{fill:#f2f2f5;stroke:#37aadc;stroke-width:0.75;stroke-miterlimit:10;stroke-linejoin:round}
.st3{fill:#f2f2f5}
.st4,.st5{stroke-miterlimit:10}
.st4{stroke-width:0.75;fill:#f2f2f5;stroke:#fa6423;stroke-linejoin:round}
.st5{fill:none;stroke:#d2d2d7;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:3.9632,5.2842}
.st6{fill:#37aadc}
.st7,.st8{fill:none;stroke:#37aadc;stroke-width:0.75;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
.st8{stroke:#bebec3}
.st9{fill:#fa6423}
.st10{fill:#f2f2f5}
