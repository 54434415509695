@import '~@sparkpost/design-tokens/tokens';

.LegendItem {
  position: relative;
  display: block;
  padding: spacing(200);
  color: color(gray, 200);
  transition: background-color motion-duration(fast) motion-ease(in-out),
    opacity motion-duration(fast) motion-ease(in-out);

  &:hover {
    cursor: default;
    color: color(gray, 200);
  }

  &.isClickable {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: color(gray, 200); // TODO: Is this the right value?
    }
  }

  &.isHovered {
    background-color: color(gray, 200);
  }

  &.anotherIsHovered {
    opacity: 0.7;
  }
}
