@import '~@sparkpost/design-tokens/tokens';

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 375px;
}

.ErrorMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.Header {
  font-weight: 500;
  font-size: font-size(300);
  margin: 0;
}

.GaugeSvgContainer {
  position: relative;
}

.Red {
  fill: color(red, 600);
}

.Yellow {
  fill: color(yellow, 400);
}

.Green {
  fill: color(green, 600);
}

.NeedleContainer {
  position: absolute;
  left: 50%;
  right: 0;
  transform: translate(-50%, 0);
}

.NeedleSvg {
  position: relative;
}

.Needle {
  fill: color(gray, 700);
  transform-origin: 50px 64px;
  transition: 0.8s cubic-bezier(0.1, 0, 0.2, 1.15);
}

.Text {
  font-size: 5px;
  font-weight: 400;
  fill: color(gray, 700);
}

.Tick {
  stroke: color(gray, 400);
  stroke-dasharray: 3 4;
  stroke-width: 2px;
}

.Score {
  color: currentColor;
  margin-top: -#{spacing(500)};
  font-size: font-size(700);
  font-weight: 400;
  text-align: center;
}

.Description {
  display: flex;
  width: 90%;
  margin: 0 auto spacing(400);
  align-items: flex-start;
  justify-content: center;
}

.DescriptionIcon {
  flex: 0 0 0;
  min-width: 48px;
}

.DescriptionContent {
  flex: 1 0 0;
  margin: 0;
  max-width: 180px;
  font-size: font-size(200);
  line-height: line-height(200);
}

.Metrics {
  text-align: center;
  display: flex;
  justify-content: space-around;
}
