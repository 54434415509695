@import '~@sparkpost/design-tokens/tokens';

.email {
  color: color(blue, 1000);
}

.validIcon {
  color: color(green, 700);
}

.invalidIcon {
  color: color(red, 700);
}

.IconWrapper {
  display: inline-block;
  width: rem(36);
  padding-right: spacing(100);
}

.Paragraph {
  margin-bottom: 0;
  padding-left: spacing(300);
}

.Validity {
  margin-bottom: 0;
}

.Header {
  margin-bottom: spacing(300);
  text-align: left;
}

.Subheader {
  text-align: left;
}

.Field {
  max-width: 500px;
  margin: 1rem 0;
}

.Submit {
  max-width: 160px;
  margin: 3em auto;
}
