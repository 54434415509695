@import '~@sparkpost/design-tokens/tokens';
@import 'src/styles/resets';

.container {
  display: block;
  margin-bottom: spacing(200);
}

.wrapper {
  user-select: none;
  outline: none;
  padding: 0;
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.month {
  margin: 0;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  padding: spacing(300);
  padding-top: 0;

  & + .month {
    border-left: 1px solid color(gray, 300);
    margin-left: spacing(400);
  }
}

.caption {
  height: spacing(600);
  padding-top: 0;
  font-weight: font-weight(medium);
  font-size: font-size(400);
  text-align: center;
}

.weekdays {
  position: relative;
}

.weekdaysRow {
  display: flex;
}

.weekday {
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  padding: spacing(100) spacing(200);
  font-size: font-size(200);
  font-weight: font-weight(medium);
  text-align: center;
  color: color(gray, 600);

  abbr {
    border: none;
    text-decoration: none;
  }
}

.body {
  position: relative;
}

.week {
  display: flex;
}

.day {
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  min-width: spacing(500);
  padding: spacing(100) spacing(200);
  margin: 1px 0;
  font-size: font-size(200);
  font-weight: font-weight(normal);
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  transition: 0.15s;

  &:focus {
    outline: none;
  }

  &:hover:not(.disabled):not(.inBetween):not(.firstSelected):not(.lastSelected) {
    background: color(gray, 300);
  }

  &:active:not(.disabled) {
    box-shadow: 0 0 0 1px color(gray, 300);
  }
}

.selected {
  color: color(gray, 700);
}

.today {
  color: color(gray, 800);
  font-weight: font-weight(semibold);
}

.inBetween {
  z-index: 100;
  background: rgba(color(blue, 400), 0.3);
  color: color(blue, 800);
  font-weight: font-weight(medium);
}

.outside {
  color: color(gray, 800);
  opacity: 0.5;
  pointer-events: none;

  &.inBetween {
    background: rgba(color(blue, 200), 0.12) !important;
    color: color(gray, 700) !important;
    font-weight: font-weight(normal);
  }

  &.lastSelected,
  &.firstSelected {
    background: rgba(color(blue, 700), 0.4);
    color: color(gray, 800) !important;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.33;
  color: color(gray, 800);
}

.firstSelected {
  color: color(white);
  background: color(blue, 700);
  border-top-left-radius: border-radius(200);
  border-bottom-left-radius: border-radius(200);
}

.lastSelected {
  color: color(white);
  background-color: color(blue, 700);
  border-top-right-radius: border-radius(200);
  border-bottom-right-radius: border-radius(200);
}

// Custom Components
.Navbar {
  position: absolute;
  left: 0;
  right: 0;
  top: -#{spacing(200)};
  padding: spacing(200);
}

.NavbarButton {
  @include button-reset();

  &:hover {
    .Prev,
    .Next {
      fill: color(blue, 700);
    }
  }

  &.isHidden {
    display: none;
  }
}

.NavbarButtonPrev {
  position: absolute;
  left: spacing(400);
  padding: spacing(300);
}

.NavbarButtonNext {
  position: absolute;
  right: spacing(400);
  padding: spacing(300);
}

.Prev,
.Next {
  position: static;
  top: 0;
  display: inline-block;
  cursor: pointer;
  transition: 0.15s color;
  fill: color(blue, 700);
}

.Prev {
  left: rem(24);
}

.Next {
  right: rem(24);
}
