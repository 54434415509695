@import '~@sparkpost/design-tokens/tokens';

.HelpText {
  word-break: normal;
  word-wrap: normal;
  font-size: initial;
}

.SmallHeader {
  font-size: initial;
}

.InfoIcon {
  color: color(blue, 700);
}
