@import '~@sparkpost/design-tokens/tokens';

.Typeahead {
  margin: 0 0 1rem;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.List {
  border: 1px solid color(gray, 400);
  background-color: color(white);
  box-shadow: shadow(deep);
  left: 0;
  margin-top: 3px;
  max-height: 20rem;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scale(0.97);
  transition: 0.1s ease-out;
  z-index: z-index(overlay);

  &.open {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }

  &.hasHelp {
    margin-top: -#{spacing(400)};
  }
}

.Item {
  clear: both;
  font-size: font-size(200);

  .id {
    float: right;
    font-size: font-size(100);
    color: color(gray, 700);
  }
}
