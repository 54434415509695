@import '~@sparkpost/design-tokens/tokens';

.MainLabel {
  display: block;
  font-size: font-size(500);
  font-weight: 300;

  .selected & {
    color: color(brand, orange);
  }
}

.DiscountedLabel {
  color: color(gray, 400);
  padding-right: spacing(400);
}

.SupportLabel {
  display: block;
  font-size: font-size(300);
  font-weight: font-weight(light);
}
