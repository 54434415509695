@import '~@sparkpost/design-tokens/tokens';

.EmptyDataCell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
}

.PaddedCell {
  padding: 0; // Let the component from Matchbox do the work
}

.OverflowCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: color(gray, 700);
}

.Red {
  color: color(red, 700);
}

.Green {
  color: color(green, 800);
}

.WowWrapper {
  display: flex;
  & > * {
    white-space: nowrap;
    text-align: right;
  }
}

.WowCaret {
  flex: 1 0 0;
}

.WowValue {
  flex: 0 0 0;
  min-width: 50px;
}

.InfoIcon {
  margin-top: -0.15em;
  color: color(blue, 700);
}
