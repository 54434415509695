@import '~@sparkpost/design-tokens/tokens';

.TabPadding {
  padding: 0 spacing(300);
}

.SecondaryActions {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: spacing(300);
  position: absolute;
  right: 0;
  top: 0;
}

.TabsWrapper {
  position: relative;

  > div {
    box-shadow: none;
  }
}

.ApiDocs {
  font-weight: font-weight(light);
  color: color(gray, 700) !important;
}
