.MessageBlock {
  display: flex;
  align-items: center;
  height: 90vh;
}

.MessageInnards {
  flex: 1 0 0;
  text-align: center;
}

.ErrorBanner {
  flex: 1 0 60vw;
}
