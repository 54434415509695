@import '~@sparkpost/design-tokens/tokens';

.EditSection {
  border-radius: 2px;
  overflow: hidden; // Fixes issue with border radius not containing editor background
  margin-bottom: 0; // Overrides styles that are on the `<Card/>`
}

.TabsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid color(gray, 400);
  align-items: center;

  > div {
    box-shadow: none;
    border-bottom: 0;

    > a {
      display: inline-flex;
      align-items: center;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin-left: spacing(300);
  }
}

.MoreButton,
.TagWrapper {
  height: 100%;
}

.TagWrapper {
  display: flex;
  align-items: center;
  padding: 0 spacing(200);
}
