@import '~@sparkpost/design-tokens/tokens';

.DivergingBar {
  :global {
    .xAxis .recharts-cartesian-axis-line {
      stroke: color(gray, 300);
    }

    .yAxis text {
      user-select: none;
      pointer-events: none;
      font-size: font-size(200);
      margin-bottom: spacing(200);
    }
  }
}
