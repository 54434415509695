@import '~@sparkpost/design-tokens/tokens';

.Heading,
.ApiHeading.ApiHeading {
  font-size: font-size(200);
  margin-bottom: spacing(100);
}

.Result {
  font-size: font-size(100);
  margin-bottom: spacing(400);
}

.ResultValue {
  text-transform: capitalize;
  font-size: 1.66rem;
  font-weight: 500;
}

.ResultDescription {
  margin-bottom: spacing(300);
}

.TabCharacter {
  padding-left: 1em;
}

.ApiHeading {
  font-size: 1rem;
  color: color(gray, 100);
}

.ApiDescription {
  margin-bottom: spacing(500);
  line-height: 1.5em;
}

// Specificity waaaars 💣💥
.ApiDescriptionLink.ApiDescriptionLink {
  color: #fff;
  text-decoration: underline;
}

.ResultList {
  max-width: 400px;
  width: 100%;
  margin-bottom: spacing(500);
}

.ResultListItem {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 60% 40%;
  padding: spacing(300) 0;

  + .ResultListItem {
    border-top: 1px solid color(gray, 700);
  }
}

.ResultListKey {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  margin: 0;
}

.CodeSnippet {
  color: color(blue, 100);
  font-size: 0.75rem;
}

.WhiteText {
  color: color(gray, 100);
}

.SubSection {
  padding: spacing(500);
}
