.BoldInline {
  display: inline-block;
}

.rightAlign {
  text-align: right;
}

.IncidentFilters {
  max-width: 25vw;
}
