@import '~@sparkpost/design-tokens/tokens';

$active-link-color: color(blue, 700);

.Navigation {
  background: color(white);
  display: grid;
  grid-gap: spacing(200);
  padding: 0 spacing(500) spacing(300) spacing(500);
  box-shadow: box-shadow(200);

  @media (min-width: media-query(sm)) {
    padding: 0 spacing(500);
    grid-template-columns: 1fr 1fr;
  }
}

.NavigationLink {
  font-size: font-size(400);
  position: relative; // allows absolute positioning within
  color: color(gray, 700);
  text-decoration: none;
  display: inline-block;
  padding: spacing(500) spacing(300);
  transition: color motion-duration(fast) motion-ease(in-out);

  &:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 12.5%; // 100% - 75% = 25% - divided by 2 is 12.5% (this centers the pseudo element horizontally!)
    bottom: 0;
    height: spacing(100);
    opacity: 0;
    visibility: hidden;
    transition: opacity motion-duration(fast) motion-ease(in-out),
      visibility motion-duration(fast) motion-ease(in-out);
  }

  &:hover,
  &:focus {
    color: color(gray, 800);

    &:after {
      opacity: 1;
      visibility: visible;
      background-color: color(gray, 300);
    }
  }

  &.active {
    color: $active-link-color;

    &:after {
      opacity: 1;
      visibility: visible;
      background-color: $active-link-color;
    }
  }

  @media (min-width: media-query(sm)) {
    padding: spacing(500) spacing(300);
  }
}

.NavigationActiveLink {
  position: relative; // allows absolute positioning within
  color: $active-link-color;
}

.NavigationPrimaryArea {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@keyframes saved {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
