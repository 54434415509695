@import '~@sparkpost/design-tokens/tokens';

.Dropzone {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: spacing(100) spacing(300);
  border-radius: border-radius(200);
  color: color(gray, 800);
  border: 1px solid color(gray, 400);
  font-size: font-size(50);
  font-weight: font-weight(normal);
  line-height: line-height(200);
  border-style: dashed;
  text-align: center;
  cursor: pointer;
  transition: border-color motion-duration(fast) motion-ease(in-out);

  &:hover,
  &:focus-within {
    border-color: color(gray, 500);
    border-style: solid;
  }
}

.DropzoneActive,
.DropzoneActive:hover {
  border-color: color(blue, 500);
  border-style: solid;
  box-shadow: 0 0 0 1px color(blue, 500);
}

.DropzoneDisabled {
  background-color: color(gray, 200);
  cursor: not-allowed;
}
