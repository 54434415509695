@import '~@sparkpost/design-tokens/tokens';

.Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 375px;
}

.ErrorMessage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.Metrics {
  text-align: center;
  display: flex;
  justify-content: left;
}

.Injections,
.DoDChange {
  width: 140px;
  text-align: left;
}

.Divider {
  margin-top: spacing(300);
  height: 60%;
  margin-right: spacing(500);
  width: 1px;
  background: color(gray, 300);
}

.xDividerLabel {
  fill: color(gray, 700);
  font-size: font-size(100);
  line-height: line-height(500);
  text-anchor: start;
}
