@import '~@sparkpost/design-tokens/tokens';

.SummaryTable {
  table {
    margin: 0; // override
    table-layout: fixed;
  }
}

.PaginationWrapper {
  display: flex;
  align-items: baseline;
  border-top: none;
  background: none;
  border-radius: initial;
  margin-top: spacing(400);
  margin-bottom: spacing(400);
}

.PageButtons {
  flex: 1 0 0;
}

.Pagination {
  margin: 0 !important;

  // Previous button
  & > button:first-child {
    border-left: none;
  }

  .Pagination {
    button,
    button:active,
    button:focus {
      border-color: transparent;
      background: transparent;
    }
  }
}
