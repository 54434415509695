@import '~@sparkpost/design-tokens/tokens';

.JobStatusTagContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Complete {
  color: color(green, 800);
}

.Failed {
  color: color(red, 700);
}

.Loading {
  color: color(blue, 800);
}

.Ready {
  color: color(blue, 700);
}
