@import '~@sparkpost/design-tokens/tokens';

.FormContainer {
  padding: 0 0 0 spacing(400);
}

.CountBox {
  border: none;
  text-align: left;
  font-size: font-size(300);
  line-height: line-height(200);
  font-weight: font-weight(normal);
  margin-top: 0.5em;
  margin-bottom: 2rem;

  .Number {
    font-weight: 500;
  }
}

.Cost {
  font-size: font-size(300);
  line-height: line-height(200);
  font-weight: font-weight(normal);
  display: inline-block;
  margin-top: 0.5em;
  margin-right: 1em;
}

.Header {
  font-size: font-size(400);
}

.SubHeader {
  font-weight: font-weight(medium);
}

.DescriptionParagraph {
  margin-bottom: spacing(300);
  font-size: font-size(300);
}

.LoadingCostContainer {
  margin-top: 0.5em;
  margin-right: 1em;
}
