@import '~@sparkpost/design-tokens/tokens';

.TooltipIcon {
  color: color(blue, 700);
  margin-top: -0.05em;
  margin-left: 1ch;
}

.content {
  padding: 10px;
}
