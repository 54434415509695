@import '~@sparkpost/design-tokens/tokens';
@import './phone.scss';

.PreviewContainer {
  height: 90vh; // must match editor wrapper
  min-height: $phone-container-min-height;
  min-width: 325px;
  overflow: scroll;
  padding: $phone-padding;
}

.PreviewScreen {
  border: 1px solid color(gray, 400);
  height: 100%;
  overflow: scroll;
  position: relative;
}

.PreviewMobileContainer {
  height: 90vh; // must match editor wrapper
  min-height: $phone-container-min-height;
  padding: $phone-padding 0;
}

.PreviewMobilePhone {
  background: color(gray, 800);
  border: 1px solid color(gray, 400);
  border-radius: 40px;
  height: $phone-height;
  margin: 0 auto;
  padding: 40px 10px;
  width: $phone-width;
}

.PreviewMobilePhoneScreen {
  background: color(white);
  border: 1px solid color(gray, 800);
  border-radius: 2px;
  height: $phone-screen-height;
  overflow: scroll;
  position: relative;
  width: $phone-screen-width;
}
