@import '~@sparkpost/design-tokens/tokens';

.PreviewHeader {
  padding: spacing(400);
}

.PreviewHeaderSubject {
  border-bottom: 1px solid color(gray, 400);
  font-size: font-size(300);
  padding-bottom: spacing(400);
}

.PreviewHeaderContact {
  display: flex;
  padding-top: spacing(400);
}

.PreviewHeaderAvatar {
  background: color(gray, 500);
  border-radius: 2px;
}

.PreviewHeaderAddresses {
  min-width: 0;
  padding-left: spacing(400);
  position: relative;
}

.PreviewHeaderFrom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
