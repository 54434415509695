@import '~@sparkpost/design-tokens/tokens';

.Container {
  padding: spacing(400);
}

.Header {
  margin-bottom: spacing(200);
}

.Description {
  margin: spacing(400) 0;
  font-weight: font-weight(light);
}

.List {
  max-width: 600px;
}
