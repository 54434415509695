@import '~@sparkpost/design-tokens/tokens';

// TODO: Test this out

.TooltipDate {
  border-bottom: 1px solid color(gray, 500);
  color: color(gray, 100);
  font-size: rem(15);
  padding: spacing(200) spacing(400);
}

.TooltipIcon {
  color: color(blue, 700);
  margin-left: spacing(200);
  margin-top: -4px;
}

.TooltipMetrics {
  padding: spacing(200) spacing(400);
}
