@import '~@sparkpost/design-tokens/tokens';

.CookieConsent {
  position: fixed;
  left: 50%;
  transform: translateX(-50%); // Absolute centering hack
  bottom: spacing(800);
  padding-right: spacing(400);
  padding-left: spacing(400);
  z-index: z-index(overlay) + 1;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.ConsentBar {
  pointer-events: auto;
}
