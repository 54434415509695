.st0{fill:#828287}
.st1{fill:#bebec3}
.st2{fill:#d2d2d7}
.st3{fill:#f2f2f5;stroke:#37aadc;stroke-width:0.75;stroke-miterlimit:10;stroke-linejoin:round}
.st4{fill:#f2f2f5}
.st5{stroke:#fa6423;stroke-width:0.75;stroke-miterlimit:10;stroke-linejoin:round}
.st5,.st6{fill:#f2f2f5}
.st7{fill:#aaaaaf}
.st8{fill:#fa6423}
.st10,.st9{fill:none;stroke-width:0.75}
.st9{stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke:#37aadc}
.st10{stroke:#bebec3}
.st10,.st11,.st12{stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
.st11{stroke-dasharray:3.9632,5.2842;fill:none;stroke:#d2d2d7}
.st12{fill:#f2f2f5;stroke:#fa6423;stroke-width:.75}
.st13{fill:#c8c8cd}
.st14{fill:#37aadc}
