@import '~@sparkpost/design-tokens/tokens';

// Invisible on mobile
.HeaderAccountBanner {
  display: none;

  @media (min-width: media-query(md)) {
    display: flex;
  }
}
