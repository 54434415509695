@import '~@sparkpost/design-tokens/tokens';

.TagWrapper {
  margin: 0 spacing(100);
}

// The Tooltip component sets the display to inline block,
// which messes up the Column flex layout
.TimezoneTooltipWrapper {
  > span {
    display: inherit;
  }
}

.Conditional {
  font-style: italic;
}

.Hidden {
  visibility: hidden;
}
