@import '~@sparkpost/design-tokens/tokens';

.RecentActivity {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: spacing(400);
  margin-bottom: 0;

  @media (min-width: media-query(lg)) {
    grid-template-columns: 1fr 1fr 1fr 1fr; // This ensures that each Panel is full width on wider screens
  }
}

// 😬 Kind of hacky - this is to target the div following the accent
// If the accent were ever removed, this would probably break
.RecentActivityPanel > div:not(:first-child) {
  height: 100%;
}

.ListItem {
  height: 100%;
}

.Panel {
  height: 100%; // Fills grid column to its max
  margin: 0; // Overriding Matchbox defaults
}

.PanelContent {
  display: grid;
  grid-template-rows: 2fr 1fr;
  height: 100%;
}

.PanelStack {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

.TemplateName {
  align-self: unset;
}

.Date {
  color: color(gray, 700);
  font-size: font-size(200);
}

.Status {
  color: color(gray, 700);
  font-size: font-size(200);
}

.StatusContent {
  margin-left: spacing(200);
}

.Meta {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
}

.Actions {
  align-self: center;
  justify-self: end;
}

.RecentActivityBorder {
  margin: 0;
}

.Link {
  height: 100%;
  display: flex;
  align-items: center;
}

.PanelFooter {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
