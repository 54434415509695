@import '~@sparkpost/design-tokens/tokens';

.Title {
  display: inline-flex;
  align-items: center;
  height: 100%;
  margin-bottom: spacing(500);
}

.ValueLabel {
  display: inline;
  font-weight: font-weight(normal);
  font-size: font-size(300);
}

.Tags,
.FirstTag,
.TagsWithIcon {
  margin: 0 spacing(100) spacing(300);
}

.Icon {
  margin: 0 spacing(200) spacing(100);
}

.FirstTag {
  margin-left: 0;
}

.TagsWithIcon {
  position: relative;
  top: 2px;

  .Icon {
    margin-left: 0;
    height: spacing(400);
    width: spacing(400);
  }
  .TagText {
    margin: 0 0 0 spacing(100);
  }
}
