@import '~@sparkpost/design-tokens/tokens';

.EditSettings {
  display: grid;
  grid-template-columns: 1fr;
  padding: spacing(500);
  grid-gap: spacing(500);

  // It's a little odd to be using `em` units for a viewport size? I might be missing something
  @media only screen and (min-width: 60em) {
    grid-template-columns: 1fr 1fr;
  }
}
