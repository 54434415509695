@import '~@sparkpost/design-tokens/tokens';

.Typeahead {
  margin: 0;
  position: relative;
}

.List {
  background: color(white);
  border-radius: border-radius(0);
  border: 1px solid color(gray, 400);
  box-shadow: box-shadow(100);
  left: 0;
  margin-top: 3px;
  max-height: 20rem;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  transform: scale(0.96);
  transition: opacity motion-duration(fast) motion-ease(in),
    transform motion-duration(fast) motion-ease(in);
  z-index: 1;

  &.open {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1);
  }
}
