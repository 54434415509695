@import '~@sparkpost/design-tokens/tokens';

.SavedIndicator {
  display: inline-flex;
  text-transform: uppercase;
  color: color(gray, 700);
  font-weight: font-weight(normal);
  letter-spacing: 0.0125rem;
}

@keyframes savedIndicator {
  0% {
    opacity: 0;
    transform: translateX(-3px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.SavedIndicatorContent {
  opacity: 0;
  animation-delay: 0.3s;
  animation: savedIndicator 0.3s ease-in-out forwards;
}
