@import '~@sparkpost/design-tokens/tokens';
@import 'src/styles/resets';

.AccountPopover {
  align-self: center;
  justify-self: end;
}

.AccountPopoverIcon {
  width: 50%;
  height: 50%;
}

.AccountPopoverHeader {
  padding: spacing(200) spacing(400);
  background-color: color(white);
  color: color(blue, 700);
  overflow: hidden;
  border-bottom: 1px solid color(gray, 300);
  border-radius: border-radius(200) border-radius(200) 0 0;
}

.AccountPopoverUserInfo,
.AccountPopoverSupplemental {
  font-size: font-size(200);
}

.AccountPopoverUserInfo {
  font-weight: font-weight(medium);
}

.AccountPopoverSupplemental {
  text-transform: capitalize;
}

.PopoverItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PopoverItemSecondaryLabel {
  color: color(blue, 800);
}
