@import '~@sparkpost/design-tokens/tokens';

.Field {
  border: none;
  margin: 0 auto;
  max-width: 630px;
  text-align: center;
}

.Help {
  font-size: font-size(300);
  line-height: 1.4em;
}

.Dropzone {
  margin: 0;
  padding: spacing(800);
  border: 1px dashed transparent;
  text-align: center;
  cursor: pointer;
  transition: 0.15s;

  &:hover,
  &.Active {
    border: 1px dashed color(gray, 300);
  }
}

.FileTypeWrapper {
  & > * {
    padding: spacing(100);
    border: 1px solid color(gray, 300);
    margin: 0 spacing(400);
  }

  path {
    fill: color(gray, 300);
  }

  @media (min-width: media-query(sm)) {
    display: flex;
    justify-content: center;

    & > * {
      padding: spacing(200);
    }
  }
}

.LoadingWrapper {
  fill: color(gray, 300);
}
