@import '~@sparkpost/design-tokens/tokens';

%LineBreakDefaults {
  display: flex;
  align-items: center;

  div {
    flex-grow: 1;
    min-width: 5%;
    padding: spacing(400) 0;
  }

  hr {
    margin: 0;
  }

  span {
    font-size: 0.75rem;
    padding: spacing(400);
  }
}

.center {
  @extend %LineBreakDefaults;
}

.left {
  @extend %LineBreakDefaults;

  div:first-child {
    flex-grow: 0;
  }
}

.right {
  @extend %LineBreakDefaults;

  div:last-child {
    flex-grow: 0;
  }
}

hr.textless {
  margin: spacing(400) 0;
}
