@import '~@sparkpost/design-tokens/tokens';

.Right {
  margin-top: spacing(200);

  @media (min-width: media-query(md)) {
    margin-top: rem(-40);
  }
}

.RightViewMode {
  margin-top: spacing(200);

  @media (min-width: media-query(md)) {
    margin-top: rem(-22);
  }
}
