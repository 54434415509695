@import '~@sparkpost/design-tokens/tokens';

.Name {
  margin-bottom: 0;
}

.Email {
  margin-bottom: 0;
  line-height: line-height(300);
  font-size: font-size(200);
}
