@import '~@sparkpost/design-tokens/tokens';

.Item {
  display: inline-block;
  white-space: nowrap;
}

.Fill {
  display: inline-block;
  margin: 0 spacing(300) 0 spacing(600);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  vertical-align: middle;
}

.Label {
  font-weight: font-weight(normal);
  font-size: font-size(300);
  white-space: nowrap;
  vertical-align: middle;
}
