@import '~@sparkpost/design-tokens/tokens';

.Center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
  transform: translate(-50%, -50%);
}

// Logo styles
.Logo {
  width: 70px;
  height: 70px;
  fill: color(blue, 700);
  animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

// Circle styles
.CircleWrapper {
  width: 60px;
  height: 60px;
  fill: none;
  animation: rotate 2s linear infinite;

  &.Small {
    width: 28px;
    height: 28px;
    animation: rotate 1s linear infinite;
  }

  &.XSmall {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    margin-bottom: 7px;
    animation: rotate 800ms linear infinite;
  }
}

.Circle {
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-dasharray: 150 200;
  stroke-dashoffset: -10;
  stroke: color(blue, 700);
  animation: dash 1.5s ease-in-out infinite;

  .Small & {
    stroke-width: 3px;
    stroke-dasharray: 100 100;
    animation: dash-small 1.5s ease-in-out infinite;
  }

  .XSmall & {
    stroke-width: 2.3px;
    stroke-dasharray: 100 100;
    animation: dash-small 1.5s ease-in-out infinite;
  }
}
@keyframes dash {
  0% {
    stroke: adjust-hue(color(blue, 700), 15);
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke: color(blue, 700);
    stroke-dasharray: 120 200;
    stroke-dashoffset: -25;
  }
  100% {
    stroke: adjust-hue(color(blue, 700), 15);
    stroke-dasharray: 120 200;
    stroke-dashoffset: -145;
  }
}

@keyframes dash-small {
  0% {
    stroke: adjust-hue(color(blue, 700), 15);
    stroke-dasharray: 1 100;
    stroke-dashoffset: 0;
  }
  50% {
    stroke: color(blue, 700);
    stroke-dasharray: 60 100;
    stroke-dashoffset: -15;
  }
  100% {
    stroke: adjust-hue(color(blue, 700), 15);
    stroke-dasharray: 80 100;
    stroke-dashoffset: -70;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
