@import '~@sparkpost/design-tokens/tokens';

.Feature {
  display: flex;
  margin: spacing(400) 0;

  .description {
    margin-right: spacing(600);
    font-size: font-size(200);
  }

  & > :first-child {
    padding-right: spacing(600);
    flex-grow: 1;
  }
}

.Label {
  font-weight: font-weight(medium);
}

.FeatureCheckIcon {
  height: rem(25);
  width: rem(25);
}

.FeatureListStatus {
  display: flex;
}

.FeatureListIcon {
  height: rem(30);
  width: rem(30);
  margin-right: spacing(200);

  &.success {
    color: color(green, 700);
  }

  &.danger {
    color: color(red, 700);
  }
}
