@import '~@sparkpost/design-tokens/tokens';

.AwsMPLogo {
  display: inline-block;
  max-height: sizing(1300);
  margin-left: spacing(550);
}

// Most of these colors do not exist as tokens -> https://design.sparkpost.com/foundations/token-reference
.st0 {
  fill: #231F20;
}

.st1 {
  fill: #58595B;
}

.st2 {
  fill: #FF9900;
}

.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FF9900;
}

.st4 {
  fill: #1D74B9;
}

.st5 {
  fill: #82C4E9;
}
