@import '~@sparkpost/design-tokens/tokens';

.panel {
  background: #3c3c40;
  border-radius: border-radius(0);
  height: 100%;
}

.preformattedText {
  color: color(white);
}

.textBody {
  color: color(white);
  padding: spacing(300);
  font-family: font-family(monospace);
}

.line {
  font-weight: 100;
}
