@import '~@sparkpost/design-tokens/tokens';

/*
 * Styles in this file are extracted and appended to the <head> tag
 */

html {
  background: color(white);
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: color(white);
  z-index: 99999;
  opacity: 1;
  transition: 0.4s 0.6s;

  // Overrides Critical CSS. Prevents FOUT.
  &.ready {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .logo__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 1.5s linear infinite;
  }
}

.logo__icon {
  display: inline-block;
  height: 120px;
}

.logo__flame {
  fill: color(gray, 300);
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

#error {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding: 0 spacing(500);
    transform: translate(0, -50%);
    text-align: center;
  }

  h3 {
    color: color(gray, 800);
  }

  p,
  a,
  a:visited {
    color: color(gray, 900);
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  &.show {
    display: block;
  }
}
