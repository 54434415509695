@import '~@sparkpost/design-tokens/tokens';

.Grid {
  display: grid;
  column-gap: spacing(400);
  row-gap: spacing(400);
  grid-template-columns: 1fr;

  @media screen and (min-width: media-query(sm)) {
    grid-template-columns: 1fr 1fr;
  }
}

.Header {
  padding: spacing(600);
  text-align: center;
}

.SendIcon {
  margin-right: spacing(400);
  transform: translate(0, -25%) rotate(-45deg);
}

.ValidationIcon {
  margin-right: spacing(400);
  transform: translate(0, -10%);
}
