@import '~@sparkpost/design-tokens/tokens';

.Check {
  color: color(blue, 700);
}

.Option,
a.Option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(200) spacing(400);
  text-decoration: none;
  color: color(gray, 800);
  transition: 0.15s;

  &:hover {
    background-color: color(blue, 200);
    color: color(blue, 700);
  }
}
