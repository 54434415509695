@import '~@sparkpost/design-tokens/tokens';

.wrapper {
  position: relative;
  display: flex;
  min-height: 100vh;
  background: color(white);
}

.FullscreenContent,
.content {
  position: relative;
  overflow: hidden;
  outline: none; // Disables focus styles - not in the tab order - only focused via skip link

  .form &,
  .largeForm & {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .fullscreen & {
    padding: 0;
    width: 100%;
  }
}

// TODO: This CSS is not great because of overrides...need to refactor when the OG theme is removed
.content {
  position: relative;
  overflow: initial;
  padding: spacing(800) spacing(400);
  padding-bottom: sizing(900);

  @media (min-width: media-query(md)) {
    padding: 0 spacing(500) spacing(400) spacing(500);
    padding-bottom: sizing(900);
  }
}

.FullscreenContent {
  padding: 0;

  @media (min-width: media-query(md)) {
    padding: 0;
  }
}

.container {
  position: relative;

  .form & {
    flex: 1 0 0;
    margin: 0 auto;
    max-width: media-query(sm);
  }

  .largeForm & {
    flex: 1 0 0;
    max-width: media-query(sm);
    margin: 0 auto;
  }
}
