@import '~@sparkpost/design-tokens/tokens';

.SubaccountFilter {
  border: 1px solid color(gray, 400);

  > span {
    display: block !important;

    > span {
      display: block !important;
    }
  }
}

.BackButton {
  color: color(blue, 700);
  left: spacing(200);
  position: absolute;
  transition: 0.15s;

  &:hover {
    color: color(blue, 700);
  }
}

.ButtonIcon {
  position: absolute;
  right: spacing(200);
  top: 50%;
  width: 24px;
  transform: translate(0, -50%) rotate(90deg);
  color: color(blue, 700); // to match other selects
}

.ButtonLabel {
  color: color(gray, 800); // match other selects
  margin-right: spacing(200);
  overflow: hidden;
  text-overflow: ellipsis; // to truncate really long subaccount names
  white-space: nowrap;
  max-width: sizing(1000);
  font-size: font-size(300);
  font-weight: font-weight(normal);
  transform: translateY(1px);
}

.Popover {
  width: 100%;

  > span {
    display: none; // remove popover carrot
  }
}

.Button {
  text-align: left;
  position: relative;

  * > span {
    display: inline-block;
  }
}

.SubaccountSearch {
  padding: spacing(200);
}

.SubaccountSearchHeader {
  border-bottom: 1px solid color(gray, 800);
  color: color(gray, 800);
  padding: spacing(200) spacing(100);
  text-align: center;
}

.PopoverContent {
  display: none;
}

.showSearch {
  display: block;
}

.showOptions {
  display: block;
}
