@import '~@sparkpost/design-tokens/tokens';

.PreviewControlBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid color(gray, 400);
  padding: 0 spacing(400);
}

.PreviewDeviceButton {
  height: 55px;
  display: inline-flex;
  align-items: center;
  color: color(gray, 600);
  margin-right: spacing(400);
  position: relative; // allows absolute positioning within
  margin-right: spacing(400);

  // Pseudo element that becomes visible when one of the device buttons becomes active
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: spacing(100);
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity motion-duration(fast) motion-ease(in-out),
      visibility motion-duration(fast) motion-ease(in-out);
  }

  &:hover,
  &:focus {
    color: color(gray, 800);

    &:after {
      opacity: 1;
      visibility: visible;
      color: color(gray, 800);
    }
  }

  &.active {
    color: color(gray, 800);

    &:after {
      opacity: 1;
      visibility: visible;
      background-color: color(blue, 700);
    }
  }
}
