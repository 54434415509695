@import '~@sparkpost/design-tokens/tokens';

.EditContents {
  display: grid;
  padding: spacing(500);
  grid-gap: spacing(500);
  grid-template-columns: 1fr;

  @media (min-width: media-query(md)) {
    grid-template-columns: 1fr 1fr;
  }
}
