@import '~@sparkpost/design-tokens/tokens';

.Select {
  min-width: sizing(900);
}

.Popover {
  width: 640px;
  padding: spacing(400);
}

.Error {
  display: inline-block;
  vertical-align: middle;
  margin-left: spacing(200);
  line-height: line-height(200);
}
