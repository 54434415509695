@import '~@sparkpost/design-tokens/tokens';

.ChartHeader {
  display: flex;
  align-items: center;
  padding: spacing(500);
}

.Title {
  font-weight: font-weight(semibold);
  font-size: font-size(400);
  margin-bottom: 0;
}

.Line {
  background: none;
  border-bottom: 0;
  margin: 0;
}

.PrimaryArea {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
